<template>
    <v-dialog
        v-model="dialog"
        @beforeClose="resetDialog"
        width="90vw"
        content-class="dialog-box"
        eager
        :attach="false"
        content
        ref="scheduleDialog"
        class="dialog-box"
        :cache="cache"
    >
        <audio v-if="allflipped" :src="assets.schedules.together" ref="introAudio" @canplay="playIntroAudio"></audio>
        <audio v-else :src="assets.schedules.chime" ref="introAudio" @canplay="playIntroAudio"></audio>
        <v-card class="pa-5 transform" :class="allflipped ? 'welldone' : 'none'" ref="schedule-card" id="schedule-card" height="90vh">
            <div v-if="imageArray.length > 0 && allflipped" class="confetti">
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
            </div>
            <div ref="scheduleTitle" id="scheduleTitle" style="height: 8vh">
                <div class="d-flex justify-center">
                    <!-- <v-img v-if="scheduleCover" class="mx-1" :src="scheduleCover" max-width="100" contain eager></v-img> -->
                    <v-row>
                        <v-col cols="10" sm="12" class="d-flex justify-center px-0 sm-px-3"
                            ><v-card-title class="ml-6 font-weight-medium title-sizing">
                                {{ scheduleTitle }}
                            </v-card-title>
                            <v-btn class="hidden-xs" color="#EF483D" fab medium dark @click.native="generatePdf"> PDF </v-btn>
                        </v-col>
                        <v-col v-if="closeBtn" cols="2" class="pa-0"
                            ><v-icon size="35" class="pl-3 mr-4" @click="dialog = false">mdi-close-box-outline</v-icon></v-col
                        >
                    </v-row>
                </div>

                <v-card-subtitle class="d-flex justify-center title-sizing"> Visual Schedule </v-card-subtitle>
                <div class="d-flex justify-center pt-8">
                    <v-card-title
                        v-if="imageArray.length > 0 && allflipped"
                        style="color: red"
                        class="font-weight-large text-h4 text-sm-h1 wellDone"
                        >Well Done🎉</v-card-title
                    >
                </div>
            </div>

            <div class="d-flex align-center justify-center" style="height: 80vh">
                <v-row v-if="imageArray && imageArray.length > 0">
                    <v-col cols="12" class="pa-0">
                        <v-carousel hide-delimiters :show-arrows="showDefaultArrows" :continuous="false" height="100%">
                            <!-- <template v-if="$vuetify.breakpoint.lg" v-slot:prev="{ on, attrs }">
                        <v-btn fab small class="rounded-pill" color="success" v-bind="attrs" v-on="on">
                           <v-icon>mdi-arrow-left-bold-outline</v-icon>
                        </v-btn>
                     </template>

                     <template v-if="$vuetify.breakpoint.lg" v-slot:next="{ on, attrs }">
                        <v-btn class="rounded-pill" fab small color="success" v-bind="attrs" v-on="on">
                           <v-icon>mdi-arrow-right-bold-outline</v-icon>
                        </v-btn>
                     </template> -->
                            <v-carousel-item v-for="(chunk, chunkIndex) in chunkArray" :key="chunkIndex" contain>
                                <v-container fluid class="d-flex align-center justify-center" style="max-width: 1200px">
                                    <v-row class="d-flex align-center justify-center">
                                        <v-col
                                            cols="12"
                                            sm="3"
                                            class="ma-0 pa-4 d-flex flex-column align-center justify-center"
                                            v-for="(card, index) in chunk"
                                            :key="index"
                                        >
                                            <div
                                                class="card flip-z"
                                                ref="cardsss"
                                                @click="
                                                    flipCard($refs.cardsss[index + chunkIndex * chunkSize]);
                                                    play();
                                                "
                                            >
                                                <v-card class="card-front pt-4" elevation="10" rounded>
                                                    <div class="img-wrapper">
                                                        <v-img :src="card.imgSrc" class="ma-auto scheduleImg" />
                                                    </div>
                                                    <v-card-subtitle class="text-image d-flex justify-center align-center mt-2">
                                                        <div class="image-text">
                                                            {{ card.stepDescription || card.firstLine }}
                                                        </div>
                                                    </v-card-subtitle>
                                                </v-card>

                                                <v-card class="card-back card-back-color d-flex flex-column justify-center align-center">
                                                    <v-card-title color="black">
                                                        <div color="black" class="image-text back-image-text">Finished!</div>
                                                    </v-card-title>
                                                    <div class="d-flex justify-center align-center flag-icon">
                                                        <v-icon size="120" color="black">fa-solid fa-flag-checkered fa-bounce</v-icon>
                                                    </div>
                                                </v-card>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-carousel-item>
                        </v-carousel>
                    </v-col>
                </v-row>

                <v-row v-else>
                    <v-col class="d-flex align-center justify-center">
                        <p>Opps... This Schedule has no steps!</p>
                    </v-col>
                </v-row>
            </div>

            <!-- <v-row v-if="!loading">
            <v-col>
               <v-btn @click.native="generatePdf">OPEN PDF</v-btn>
            </v-col>
         </v-row> -->
        </v-card>

        <printable-schedule
            id="schedule-print"
            :imageArray="imageArray"
            :schedule="schedule"
            :show-print-screen="isPrintScreenVisible"
            @toggle-print-screen="handleTogglePrintScreen"
            ref="childComponentInstance"
        >
        </printable-schedule>
    </v-dialog>
</template>

<script>
import BookDialog from "@/components/library/book-dialog.vue";
import { mapState } from "vuex";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import PrintableSchedule from "@/components/schedule/printable-schedule.vue";
import html2canvas from "html2canvas";

export default {
    data() {
        return {
            dialog: false,
            schedule: null,
            yourTitle: null,
            imageArray: [],
            images: [],
            chunkSize: 0,
            flipped: false,
            logoImage: null,
            flippedCards: [],
            allflipped: false,
            introAudioLoaded: false,
            cache: 0,
            closeBtn: false,
            swipeMessage: true,
            isPrintScreenVisible: false,
        };
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    components: { BookDialog, PrintableSchedule },

    props: ["item"],
    computed: {
        ...mapState(["assets"]),
        scheduleTitleHeight() {
            return 100;
        },
        showDefaultArrows() {
            return this.$vuetify.breakpoint.lg;
        },
        docDefinition() {
            return {
                images: {
                    building: this.assets.logo.logoFull,
                },
            };
        },
        scheduleTitle() {
            return this.schedule?.title;
        },

        scheduleCover() {
            if (!this.schedule?.data?.coverImage) return;
            // console.log("get image of image covr", this.schedule?.data);
            return this.getImage(this.schedule?.data?.coverImage);
        },
        chunkArray() {
            let chunked = [];
            for (let i = 0; i < this.imageArray.length; i += this.chunkSize) {
                const chunk = this.imageArray.slice(i, i + this.chunkSize);
                chunked.push(chunk);
            }
            // console.log("chunked array:", chunked);
            return chunked;
        },
        allCardsFlipped() {
            return this.flippedCards.length === this.imageArray.length;
        },
    },
    watch: {
        dialog(newVal) {
            // console.log("dialog value changed!", newVal);
            if (newVal == false) {
                this.logoImgResolved = false;
                this.resetDialog();
            }
        },
        allCardsFlipped(newVal) {
            if (newVal == true) {
                // console.log("all the cards are flipped!");
                this.allflipped = true;
            } else {
                this.allflipped = false;
            }
        },
    },
    methods: {
        print() {
            this.isPrintScreenVisible = !this.isPrintScreenVisible;
            this.$refs.childComponentInstance.print();
        },
        handleTogglePrintScreen() {
            this.isPrintScreenVisible = !this.isPrintScreenVisible;
        },
        resetDialog() {
            this.imageArray.map((item) => ({
                ...item,
                flipped: false,
            }));

            this.flippedCards.forEach((card) => {
                card.classList.remove("flipped");
            });
            this.flippedCards = [];

            // console.log("logging imageArray", this.imageArray);
            this.dialog = false;
        },
        playIntroAudio() {
            // Check if the audio is loaded before attempting to play it
            if (this.introAudioLoaded) {
                this.$refs.introAudio.play();
            }
        },
        handleResize() {
            if (window.innerWidth >= 768) {
                this.chunkSize = 8;
                this.closeBtn = false;
                this.swipeMessage = true;
            } else {
                this.chunkSize = 1;
                this.closeBtn = true;
                this.swipeMessage = false;
            }
            if (window.innerWidth > window.innerHeight && window.innerWidth < 900) {
                this.chunkSize = 1;
                this.swipeMessage = false;
            }
            this.width = window.innerWidth;
        },

        flipCard(card) {
            // Toggle the 'flipped' class on the card
            card.classList.toggle("flipped");
            this.flipped = !this.flipped;

            const index = this.flippedCards.indexOf(card);
            if (index === -1) {
                this.flippedCards.push(card);
            } else {
                this.flippedCards.splice(index, 1);
            }
        },

        play() {
            this.$refs.introAudio.load();
            this.$refs.introAudio.currentTime = 0;
            this.$refs.introAudio.play();
        },

        logoImg() {
            return this.assets.logo.logoPng;
        },
        async open(schedule) {
            this.loading = true;
            this.schedule = schedule;
            console.warn("schedule", schedule);
            this.dialog = true;

            // let list = schedule?.data?.scheduleList || [];
            // this.imageArray = await Promise.all(
            //    list.map(async (item) => {
            //       let formettedItem = {
            //          ...item,
            //          id: item._id,
            //          imgSrc: await this.getImage(item),
            //       };
            //       console.log("logging formatted Item", formettedItem);
            //       return formettedItem;
            //    })
            // );

            let scheduleSteps = schedule?.data?.scheduleSteps || schedule?.data?.scheduleList || [];
            this.imageArray = await Promise.all(
                scheduleSteps.map(async (step) => {
                    let imageId = step?.image?._id || step?._id;
                    let formettedItem = {
                        id: imageId,
                        imgSrc: await this.getImage(imageId),
                        stepDescription: step?.description,
                    };
                    // console.log("logging formatted Item", formettedItem);
                    return formettedItem;
                })
            );

            // console.log("image array", this.imageArray);
            this.logoImage = await this.logoImg();

            console.warn("OPEN (scheduleTitleHeight):", this.scheduleTitleHeight);

            this.loading = false;
        },
        getImage(item) {
            if (item && item._id) if (!item?._id) return;

            // console.log("Get Image called!");
            return `${this.$fluro.asset.imageUrl(item?._id || item)}&noRedirect=true`;
        },

        convertWebPToPngDataUrl(webpUrl) {
            return new Promise((resolve, reject) => {
                // Create a new Image object
                let img = new Image();

                img.crossOrigin = "anonymous";

                // Once the WebP image has loaded
                img.onload = () => {
                    // Create a new canvas element and set its dimensions to the dimensions of the image
                    let canvas = document.createElement("canvas");
                    canvas.width = img.width;
                    canvas.height = img.height;

                    // Draw the WebP image onto the canvas
                    let ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0);

                    // Convert the canvas to a PNG data URL
                    let pngDataUrl = canvas.toDataURL("image/png");
                    console.warn("DataURL Result:", pngDataUrl);
                    // Resolve the promise with the PNG data URL
                    resolve(pngDataUrl);
                };

                // If there is an error loading the WebP image, reject the promise with the error message
                img.onerror = () => {
                    reject(new Error("Error loading WebP image."));
                };

                // Set the src attribute of the Image object to the WebP image URL
                img.src = webpUrl;
            });
        },

        // async getBase64ImageFromImgURL(imgSrc) {
        //    const response = await fetch(imgSrc);
        //    const blob = await response.blob();
        //    const reader = new FileReader();
        //    return new Promise((resolve, reject) => {
        //       reader.onerror = reject;
        //       reader.onload = () => {
        //          const base64 = reader.result.replace(/^data:image\/(png|jpeg);base64,/, "data:image/png;base64,");
        //          console.warn("resulting form base 64", base64);
        //          resolve(base64);
        //       };
        //       reader.readAsDataURL(blob);
        //    });
        // },

        getBase64ImageFromURL(url) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.setAttribute("crossOrigin", "anonymous");

                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    canvas.width = img.width;
                    canvas.height = img.height;

                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0);

                    const dataURL = canvas.toDataURL("image/png");

                    resolve(dataURL);
                };

                img.onerror = (error) => {
                    reject(error);
                };

                img.src = url;
            });
        },

        // async generatePdf() {
        //    this.images = await Promise.all(
        //       this.imageArray.map(async (item, index) => {
        //          //?access_token=${this.$fluro.applicationToken}
        //          return {
        //             url: `${await this.getBase64ImageFromURL(`${item.imgSrc}&noRedirect=true`)}`,
        //             // url: item.imgSrc,
        //             caption: item.firstLine,
        //          };
        //       })
        //    );

        //    console.warn("this.images:", this.images);

        //    this.redSparrowLogo = await this.getBase64ImageFromURL(this.assets.logo.logoPng);

        //    const footer = {
        //       image: await this.getBase64ImageFromURL(this.assets.logo.logoPng),
        //       alignment: "center",
        //       width: 60,
        //       pageBreak: "after", // Set pageBreak to 'after' to ensure footer appears at the bottom of the page
        //    };

        //    const header = {
        //       text: this.scheduleTitle,
        //       fontSize: 25,
        //       alignment: "center",
        //       margin: 10,
        //    };

        //    const docDefinition = {
        //       pageOrientation: "landscape",
        //       pageMargins: [7, 55, 7, 70],
        //       content: [],
        //       header: header,
        //       footer: footer,
        //       alignment: "center",
        //    };

        //    const numColumns = this.images.length == 5 || this.images.length == 6 ? 3 : 4;
        //    const columnWidth = 60;
        //    const rowHeight = 140;
        //    const rowHeight200 = 125;
        //    let rowCount = 0;
        //    let columnCount = 0;
        //    let container = {
        //       table: {
        //          body: [],
        //       },
        //    };
        //    let columnGroup = {
        //       table: {
        //          body: [],
        //       },
        //    };

        //    for (let i = 0; i < this.images.length; i++) {
        //       if (rowCount === 0) {
        //          columnGroup = {
        //             columns: [],
        //             alignment: "center",
        //          };
        //          columnCount = 0;
        //       }

        //       const captionColumn = {
        //          stack: [
        //             {
        //                text: this.images[i].caption || "", // use empty string if caption is undefined or null
        //                margin: [0, 0, 0, 0],
        //                widths: 10,
        //                fontSize: 15,
        //                alignment: "center",
        //             },
        //             {
        //                text: this.images[i].caption ? "" : "!",
        //                opacity: 0,
        //             },
        //          ],
        //       };

        //       const imageTable = {
        //          table: {
        //             widths: [rowHeight],
        //             heights: [rowHeight],
        //             body: [
        //                [
        //                   {
        //                      image: this.images[i].url,
        //                      widths: rowHeight,
        //                      heights: rowHeight,

        //                      border: [true, true, true, true],
        //                      fit: [rowHeight, rowHeight],
        //                   },
        //                ],
        //             ],
        //          },
        //          margin: [0, 0, 0, 0],
        //          alignment: "center",
        //       };

        //       const emptyTable = {
        //          table: {
        //             widths: [rowHeight],
        //             body: [[]],
        //          },
        //       };

        //       const contentTable = {
        //          table: {
        //             widths: [150],
        //             heights: [180],
        //             body: [
        //                [
        //                   {
        //                      stack: [imageTable, captionColumn],
        //                      alignment: "center",
        //                   },
        //                ],
        //             ],
        //             alignment: "center",
        //          },
        //       };

        //       columnGroup.columns.push(
        //          { width: "*", text: "" },
        //          {
        //             width: "auto",
        //             stack: [contentTable],
        //             margin: [0, 10, 0, 10],
        //          },
        //          { width: "*", text: "" }
        //       );

        //       rowCount++;
        //       columnCount++;
        //       console.log("Column Group:", columnGroup.columns);

        //       if (rowCount === numColumns || i === this.images.length - 1) {
        //          // const emptyTablesToAdd = numColumns - rowCount;
        //          // for (let j = 0; j < emptyTablesToAdd; j++) {
        //          //    columnGroup.columns.push({
        //          //       stack: [emptyTable],
        //          //       margin: [0, 5, 0, 25],
        //          //    });
        //          // }
        //          if (rowCount === 2) {
        //             docDefinition.content.push({ text: "" });
        //          }

        //          docDefinition.content.push(columnGroup);
        //          rowCount = 0;
        //       }
        //    }

        //    console.log(docDefinition.content);
        //    pdfMake.createPdf(docDefinition).open();
        //    // pdfMake.createPdf(docDefinition).download();
        // },

        async generatePdf() {
            // let schedule = this.$refs["schedule-card"];
            let schedule = document.getElementById("schedule-print");
            const canvas = await html2canvas(schedule, {
                useCORS: true,
                backgroundColor: "#ffffff",

                // onrendered: function (canvas) {
                //    console.log("canvas", canvas);
                // },
            });

            const imageData = canvas.toDataURL("image/png");

            // const header = {
            //    text: this.scheduleTitle,
            //    fontSize: 25,
            //    alignment: "center",
            //    margin: 10,
            // };

            const header = {
                stack: [
                    { text: this.scheduleTitle, fontSize: 25, bold: true, alignment: "center" },
                    { text: "Visual Schedule", fontSize: 15, italics: true, alignment: "center" },
                ],
                margin: 10,
            };

            const footer = {
                image: await this.getBase64ImageFromURL(this.assets.logo.logoPng),
                alignment: "center",
                width: 60,
                pageBreak: "after", // Set pageBreak to 'after' to ensure footer appears at the bottom of the page
            };

            const docDefinition = {
                pageSize: "A4",
                pageOrientation: "landscape",
                pageMargins: [0, 85, 0, 65],
                content: [
                    {
                        alignment: "center",
                        image: imageData,
                        width: 800,
                        scale: 1, // scale down the image by 0.8
                    },
                ],
                header: header,
                footer: footer,
            };

            pdfMake.createPdf(docDefinition).open();
        },
    },
    mounted() {
        this.loading = false;
        this.$refs.introAudio.addEventListener("canplay", () => {
            this.introAudioLoaded = true;
        });
    },
};
</script>

<style lang="scss">
.dialog-box {
    overflow: hidden;
    .theme--light.v-card {
        background-color: white;
    }

    .wellDone {
        z-index: 10;
    }

    .v-dialog--active {
        border-radius: 24px !important;
    }

    .no-overflow {
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .premium-icon {
        position: absolute;
        left: 30px;
        color: blue;
    }
    .image-text {
        text-align: center;
        word-break: keep-all;
        height: 3.5em;
        font-size: large;
        padding: 4px;
    }

    .v-card__subtitle,
    .v-card__text,
    .v-card__title {
        padding: 0;
    }

    @media (min-width: 768px) {
        .image-text {
            font-size: small;
        }
        .back-image-text {
            font-size: medium;
        }
    }

    @media (min-width: 1181px) {
        .image-text {
            font-size: small;
        }
        .back-image-text {
            font-size: x-large;
        }
    }

    .shadow {
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.25) 5px 4px 5px 0px;
    }
    .card-background {
        background-color: var(--v-primary-base);
    }

    .confirmed-step {
        opacity: 0.5;
    }

    .theme--dark.v-card {
        background-color: rgb(249, 249, 249);
        color: #090808;
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
    }

    .theme--dark.v-card.card-back-color {
        background-color: rgb(249, 249, 249);
        color: #060505;
        padding-top: 2rem;
    }

    .text-image.v-card__subtitle {
        color: rgb(0 0 0);
    }

    //flipping effect

    .card {
        position: relative;
        width: 60vw;
        height: 55vh;
        cursor: pointer;
    }

    .card-front,
    .card-back {
        position: absolute;
        font-size: 28pt;
        line-height: 100px;
        text-align: center;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        background: #282a3a;
        transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
        backface-visibility: hidden;
    }

    .card-back {
        background: #fdf8e6;
        transform: rotateY(180deg);
        user-select: none;
    }

    .flip-z .card-back {
        transform: rotateY(180deg) rotateZ(50deg);
    }

    .flip-z.flipped .card-front {
        transform: rotateY(180deg) rotateZ(50deg);
    }

    .card.flipped .card-front {
        transform: rotateY(180deg);
    }

    .card.flipped .card-back {
        transform: rotateY(0) rotateZ(0);
    }

    // dialogbox to be scrollable

    .dialog--fullscreen {
        height: 100%;
        max-height: calc(100vh - 64px);
        overflow-y: auto;
    }

    //schedule title

    .title-sizing {
        font-size: 1rem;
    }

    //schedule images

    .scheduleImg {
        width: 50vw;
        height: 50vw;
        z-index: 10;
        border: 3px solid rgb(145, 142, 142);
        border-radius: 50%;
        object-fit: fill;
    }

    @media (min-width: 374px) {
        .scheduleImg {
            width: 50vw;
            height: 50vw;
        }
        .card {
            position: relative;
            width: 70vw;
            height: 80vw;
            cursor: pointer;
        }
        .title-sizing {
            font-size: 1rem;
        }
    }

    @media (min-width: 413px) {
        .title-sizing {
            font-size: 1.2rem;
        }
    }

    @media (min-width: 767px) {
        .scheduleImg {
            width: 6rem;
            height: 6rem;
        }
        .card {
            position: relative;
            width: 150px;
            height: 210px;
            cursor: pointer;
        }
        .title-sizing {
            font-size: 2.2rem;
            padding: 1rem;
        }
    }

    @media (min-width: 830px) {
        .scheduleImg {
            width: 8rem;
            height: 8rem;
        }
        .card {
            position: relative;
            width: 180px;
            height: 220px;
            cursor: pointer;
        }
    }

    @media (min-width: 1181px) {
        .card {
            position: relative;
            width: 220px;
            height: 285px;
            cursor: pointer;
        }

        .scheduleImg {
            width: 11rem;
            height: 11rem;
        }
    }

    @media only screen and (min-width: 653px) and (max-width: 896px) and (orientation: landscape) {
        .card {
            position: relative;
            width: 40vw;
            height: 48vh;
            cursor: pointer;
        }

        .theme--dark.v-card.card-back-color {
            padding-top: 1rem;
        }

        .scheduleImg {
            width: 14vw;
            height: 14vw;
        }

        .title-sizing {
            font-size: 1.2rem;
            padding: 0;
        }
        .image-text {
            font-size: large;
            height: 2.5em;
            padding: 0;
        }
    }

    // arrow-btn

    // .v-btn--fab.v-size--small {
    //    height: 20vh;
    //    width: 40px;
    // }

    // .v-window__prev,
    // .v-window__next {
    //    top: calc(50% - 10vh);
    // }

    // .theme--light.v-card.welldone {
    //    background-color: rgb(104, 32, 32);
    // }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    .confetti {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .confetti-piece {
        position: absolute;
        width: 10px;
        height: 30px;
        background: #ffd300;
        top: 0;
        opacity: 0;
    }
    .confetti-piece:nth-child(1) {
        left: 7%;
        transform: rotate(-40deg);
        animation: makeItRain 1000ms infinite ease-out;
        animation-delay: 182ms;
        animation-duration: 1116ms;
    }
    .confetti-piece:nth-child(2) {
        left: 14%;
        transform: rotate(4deg);
        animation: makeItRain 1000ms infinite ease-out;
        animation-delay: 161ms;
        animation-duration: 1076ms;
    }
    .confetti-piece:nth-child(3) {
        left: 21%;
        transform: rotate(-51deg);
        animation: makeItRain 1000ms infinite ease-out;
        animation-delay: 481ms;
        animation-duration: 1103ms;
    }
    .confetti-piece:nth-child(4) {
        left: 28%;
        transform: rotate(61deg);
        animation: makeItRain 1000ms infinite ease-out;
        animation-delay: 334ms;
        animation-duration: 708ms;
    }
    .confetti-piece:nth-child(5) {
        left: 35%;
        transform: rotate(-52deg);
        animation: makeItRain 1000ms infinite ease-out;
        animation-delay: 302ms;
        animation-duration: 776ms;
    }
    .confetti-piece:nth-child(6) {
        left: 42%;
        transform: rotate(38deg);
        animation: makeItRain 1000ms infinite ease-out;
        animation-delay: 180ms;
        animation-duration: 1168ms;
    }
    .confetti-piece:nth-child(7) {
        left: 49%;
        transform: rotate(11deg);
        animation: makeItRain 1000ms infinite ease-out;
        animation-delay: 395ms;
        animation-duration: 1200ms;
    }
    .confetti-piece:nth-child(8) {
        left: 56%;
        transform: rotate(49deg);
        animation: makeItRain 1000ms infinite ease-out;
        animation-delay: 14ms;
        animation-duration: 887ms;
    }
    .confetti-piece:nth-child(9) {
        left: 63%;
        transform: rotate(-72deg);
        animation: makeItRain 1000ms infinite ease-out;
        animation-delay: 149ms;
        animation-duration: 805ms;
    }
    .confetti-piece:nth-child(10) {
        left: 70%;
        transform: rotate(10deg);
        animation: makeItRain 1000ms infinite ease-out;
        animation-delay: 351ms;
        animation-duration: 1059ms;
    }
    .confetti-piece:nth-child(11) {
        left: 77%;
        transform: rotate(4deg);
        animation: makeItRain 1000ms infinite ease-out;
        animation-delay: 307ms;
        animation-duration: 1132ms;
    }
    .confetti-piece:nth-child(12) {
        left: 84%;
        transform: rotate(42deg);
        animation: makeItRain 1000ms infinite ease-out;
        animation-delay: 464ms;
        animation-duration: 776ms;
    }
    .confetti-piece:nth-child(13) {
        left: 91%;
        transform: rotate(-72deg);
        animation: makeItRain 1000ms infinite ease-out;
        animation-delay: 429ms;
        animation-duration: 818ms;
    }
    .confetti-piece:nth-child(odd) {
        background: #7431e8;
    }
    .confetti-piece:nth-child(even) {
        z-index: 1;
    }
    .confetti-piece:nth-child(4n) {
        width: 5px;
        height: 12px;
        animation-duration: 2000ms;
    }
    .confetti-piece:nth-child(3n) {
        width: 3px;
        height: 10px;
        animation-duration: 2500ms;
        animation-delay: 1000ms;
    }
    .confetti-piece:nth-child(4n-7) {
        background: red;
    }
    @keyframes makeItRain {
        from {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        to {
            transform: translateY(350px);
        }
    }
    .pdfmake-table {
        width: 100px; /* set to desired width */
        height: 100px; /* set to desired height */
    }
}
</style>
