<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="auto">
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                    <div class="drop-zone flex-column flex-wrap justify-center d-flex align-center">
                        <v-icon size="50" color="black">mdi-plus-circle-outline</v-icon>
                        <span class="text-center black--text font-weight-bold">Add Image</span>
                    </div>
                </div>
            </template>

            <div class="image-browser">
                <div class="image-browser-header">
                    <div class="image-browser-nav">
                        <a :class="{ active: state === 'gallery' }" @click="state = 'gallery'">Image Library</a>
                        <a :class="{ active: state === 'uploads' }" @click="state = 'uploads'">Your Uploads</a>
                    </div>
                </div>
                <template v-if="state === 'gallery'">
                    <div class="image-browser-header">
                        <div class="search-padder">
                            <div class="search">
                                <input v-model="keywords" placeholder="Search" />
                                <a @click="keywords = ''">
                                    <v-icon v-if="keywords">fa-times</v-icon>
                                    <v-icon v-else>fa-search</v-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="image-browser-body">
                        <div class="empty" v-if="terms && !filteredGalleryImages.length">No images found for "{{ terms }}".</div>
                        <div class="grid" v-else>
                            <div class="cell" v-for="(image, i) in filteredGalleryImages" :key="i">
                                <v-img
                                    class="dialog-image"
                                    :ref="image._id"
                                    :id="image._id"
                                    :src="image.imgSrc"
                                    cover
                                    eager
                                    @click="addImage($event, stepId, image._id)"
                                />
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="image-browser-header">
                        <div v-if="uploading" class="uploading">
                            <div>Uploading {{ progress }}%</div>
                            <v-progress-linear :value="progress"></v-progress-linear>
                        </div>
                        <div v-else>
                            <filedrop multiple accept="image/jpg, image/jpeg, image/png" @files="imageSelected">
                                <div class="dropzone"><v-icon>mdi-upload</v-icon> Click or drag an image here to upload</div>
                            </filedrop>
                            <v-alert
                                v-model="fileTypeError"
                                class="ma-0"
                                text
                                type="error"
                                transition="fade-transition"
                                icon="mdi-cloud-alert"
                            >
                                Invalid file type. File must be of type jpg, jpeg or png.
                                <template #close>
                                    <v-icon color="error" @click="fileTypeError = false">mdi-close-circle</v-icon>
                                </template>
                            </v-alert>
                        </div>
                        <!-- <v-file-input accept="image/jpg, image/jpeg, image/png" multiple  @change="imageSelected"></v-file-input> -->
                    </div>

                    <div class="image-browser-body">
                        <div class="grid">
                            <div class="cell" v-for="(image, i) in filteredUserImages" :key="i">
                                <v-img
                                    class="dialog-image"
                                    :ref="image._id"
                                    :id="image._id"
                                    :src="image.imgSrc"
                                    cover
                                    eager
                                    @click="addImage($event, stepId, image._id)"
                                />
                            </div>
                        </div>
                    </div>
                </template>
                <div class="image-browser-footer">
                    <v-btn @click="close()">Cancel</v-btn>
                </div>
            </div>
            <!-- <v-card> -->
            <!-- <v-card-title class="text-h5 grey lighten-2">
               Add Image
            </v-card-title>
            <v-file-input
               v-if="true"
               class=" px-5"
               accept="image/jpg, image/jpeg, image/png"
               prepend-icon="mdi-camera"
               small-chips
               multiple
               label="Upload one or multiple images"
               @change="imageSelected"
            ></v-file-input> -->
            <!-- <template>
                    <v-container class="px-0 d-flex justify-center" fluid>
                        <v-switch class="toggleSwitch" color="indigo" value="indigo" inset v-model="switch1" :label="switchNames"></v-switch>
                    </v-container>
                </template> -->
            <!-- <v-progress-linear :value="progress"></v-progress-linear> -->
            <!-- <v-card-actions v-if="!switch1" class="d-flex flex-column justify-center align-center">
                    <div class="image-dialog-gallery">
                        <v-col v-for="(image, i) in imageArray" :key="i">
                            <template v-if="image.data.isPremium && false">
                                <v-img class="dialog-image" :ref="image._id" :id="image._id" :src="image.imgSrc" cover eager @click="addImage($event, stepId, image._id)" />
                            </template>
                            <template v-else-if="image.data.isPremium">
                                <div class="relative image-mask">
                                    <v-img class="dialog-image" :ref="image._id" :id="image._id" :src="image.imgSrc" cover eager>
                                        <v-icon class="premium-mask" size="40">mdi-lock</v-icon>
                                    </v-img>
                                </div>
                            </template>
                            <template v-else>
                                <div class="relative">
                                    <v-img class="dialog-image" :src="image.imgSrc" :ref="image._id" :id="image._id" @click="addImage($event, stepId, image._id)" />
                                </div>
                            </template>
                        </v-col>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="action-container">
                        <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
                    </div>
                </v-card-actions>
                <v-card-actions v-else class="d-flex flex-column justify-center align-center">
                    <div class="image-dialog-gallery">
                        <v-col v-for="(image, i) in imageArrayUser" :key="i">
                            <template v-if="image.data.isPremium && false">
                                <v-img class="dialog-image" :ref="image._id" :id="image._id" :src="image.imgSrc" cover eager @click="addImage($event, stepId, image._id)" />
                            </template>
                            <template v-else-if="image.data.isPremium">
                                <div class="relative image-mask">
                                    <v-img class="dialog-image" :ref="image._id" :id="image._id" :src="image.imgSrc" cover eager>
                                        <v-icon class="premium-mask" size="40">mdi-lock</v-icon>
                                    </v-img>
                                </div>
                            </template>
                            <template v-else>
                                <div class="relative">
                                    <v-img class="dialog-image" :src="image.imgSrc" :ref="image._id" :id="image._id" @click="addImage($event, stepId, image._id)" />
                                </div>
                            </template>
                        </v-col>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="action-container">
                        <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
                    </div>
                </v-card-actions>
            </v-card> -->
        </v-dialog>
    </div>
</template>
<script>
import filedrop from "../filedrop.vue";
export default {
    name: "AddScheduleImageDialog",
    components: {
        filedrop,
    },
    props: ["addImageToFrame", "stepId", "imageArray", "imageArrayUser"],
    data() {
        return {
            dialog: false,
            progress: 0,
            bytesLoaded: 0,
            bytesTotal: 0,
            switch1: false,
            keywords: "",
            state: "gallery",
            uploading: false,
            userImages: this.imageArrayUser,
            fileTypeError: false,
        };
    },

    mounted() {
        // console.log("SEARCH TERMS:", this.imageArray, this.imageArrayUser);
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },

    methods: {
        addImage($event, stepId, imageId) {
            // console.log("ADD IMAGE", imageId);
            this.$emit("addImageToFrame", $event, stepId, imageId);
        },
        reset() {
            this.progress = 0;
            this.bytesLoaded = 0;
            this.bytesTotal = 0;
            this.uploading = false;
        },
        close() {
            this.reset();
            this.dialog = false;
        },
        async imageSelected(fileArray) {
            const self = this;
            const promises = await fileArray.reduce(async (accPromise, { file }) => {
                const acc = await accPromise;
                // Check the file type here before proceeding
                const validFile = await self.checkFileType(file);
                // .then((fileType) => {
                //     return true;
                // })
                // .catch((error) => {
                //     console.error(error);
                //     // Handle the unsupported file type
                //     self.fileTypeError = true;
                //     // alert("Unsupported file type! Please upload a JPG, JPEG or PNG image.");
                // });

                console.log("File is valid:", validFile);

                if (validFile) {
                    var formData = new FormData();

                    formData.append(
                        "json",
                        JSON.stringify({
                            _type: "image",
                            realms: ["59028fc41745ab6c2fefeb02"],
                            definition: "scheduleImageUser",
                        })
                    );
                    formData.append("file", file, file.name);

                    ///////////////////////////////////////
                    var config = {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // ‘Authorization’: ‘Bearer ’ + window.applicationData._application.apikey
                        },
                        onUploadProgress: (progressEvent) => {
                            let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                            // do whatever you like with the percentage complete
                            // maybe dispatch an action that will update a progress bar or something
                            self.progress = percentCompleted;
                            self.bytesLoaded = progressEvent.loaded;
                            self.bytesTotal = progressEvent.total;
                            ///////////////////////////////////////////////////
                            self.$forceUpdate();
                        },
                    };
                    ///////////////////////////////////////
                    console.log("Pushing valid image to promisees arrauy:");
                    acc.push(
                        new Promise(function (resolve, reject) {
                            self.$fluro.api.post(`/file/upload`, formData, config).then(async function ({ data }) {
                                const newImage = {
                                    ...data,
                                    id: data._id,
                                    imgSrc: `${self.$fluro.asset.imageUrl(data._id)}&noRedirect=true`,
                                    imgSelected: "",
                                };

                                return resolve(newImage);
                            }, reject);
                        })
                    );
                    return acc;
                } else {
                    self.fileTypeError = true;
                }
            }, Promise.resolve([]));

            console.log("Promises reduce", promises);

            if (!this.fileTypeError && promises.length) {
                self.uploading = true;
                Promise.all(promises).then(
                    function (images) {
                        console.log("Images", images);

                        self.userImages = [...self.userImages, ...images];
                        self.uploading = false;
                    },
                    function (err) {
                        self.uploading = false;
                    }
                );
            }
        },
        checkFileType(file) {
            return new Promise((resolve, _) => {
                const reader = new FileReader();
                const blob = file.slice(0, 8); // Read the first 8 bytes for PNG, 4 should be enough for JPEG

                reader.onloadend = function (e) {
                    if (e.target.readyState === FileReader.DONE) {
                        const uint = new Uint8Array(e.target.result);

                        // Check for JPEG [0xFF, 0xD8, 0xFF]
                        const isJPEG = uint[0] === 0xff && uint[1] === 0xd8 && uint[2] === 0xff;

                        // Check for PNG [0x89, 'P', 'N', 'G', 0x0D, 0x0A, 0x1A, 0x0A]
                        const isPNG =
                            uint[0] === 0x89 &&
                            uint[1] === 0x50 &&
                            uint[2] === 0x4e &&
                            uint[3] === 0x47 &&
                            uint[4] === 0x0d &&
                            uint[5] === 0x0a &&
                            uint[6] === 0x1a &&
                            uint[7] === 0x0a;

                        resolve(isJPEG || isPNG);
                    }
                };

                reader.onerror = () => resolve(false); // Resolve to false in case of an error

                reader.readAsArrayBuffer(blob);
            })
                .then((isValid) => isValid) // Resolves to true for valid JPEG/PNG, false otherwise
                .catch(() => false); // In case of any error, treat it as an invalid file
        },

        // checkFileType(file) {
        //     return new Promise((resolve, reject) => {
        //         const reader = new FileReader();

        //         // Read the first 8 bytes for PNG, 4 should be enough for JPEG
        //         const blob = file.slice(0, 8);

        //         reader.onloadend = function (e) {
        //             if (e.target.readyState === FileReader.DONE) {
        //                 const uint = new Uint8Array(e.target.result);

        //                 // Check the magic number for JPEG [0xFF, 0xD8, 0xFF]
        //                 if (uint[0] === 0xff && uint[1] === 0xd8 && uint[2] === 0xff) {
        //                     resolve("image/jpeg");
        //                 }
        //                 // Check the PNG signature [0x89, 'P' (0x50), 'N' (0x4E), 'G' (0x47), 0x0D, 0x0A, 0x1A, 0x0A]
        //                 else if (
        //                     uint[0] === 0x89 &&
        //                     uint[1] === 0x50 &&
        //                     uint[2] === 0x4e &&
        //                     uint[3] === 0x47 &&
        //                     uint[4] === 0x0d &&
        //                     uint[5] === 0x0a &&
        //                     uint[6] === 0x1a &&
        //                     uint[7] === 0x0a
        //                 ) {
        //                     resolve("image/png");
        //                 } else {
        //                     reject("Unknown file type");
        //                 }
        //             }
        //         };

        //         reader.onerror = function () {
        //             reject("Error reading file");
        //         };

        //         reader.readAsArrayBuffer(blob);
        //     });
        // },
        handleResize() {
            if (window.innerWidth < 600) {
                this.screenSize = "8vw";
            } else if (window.innerWidth >= 600 && window.innerWidth < 960) {
                this.screenSize = "5vw";
            } else {
                this.screenSize = "2.5vw";
            }
        },
    },
    computed: {
        terms() {
            return String(this.keywords || "")
                .toLowerCase()
                .trim();
        },
        filteredGalleryImages() {
            const self = this;

            let filtered = self.imageArray;

            if (self.terms.length) {
                filtered = self.imageArray.filter(function (image) {
                    let keywords = image?.keywords || [];
                    if (image?.data?.additionalKeywords) {
                        keywords = keywords.concat(image.data.additionalKeywords);
                    }
                    const match = keywords.some(function (word) {
                        return word.includes(self.terms);
                    });

                    return match;
                });
            }

            return filtered;
        },
        filteredUserImages() {
            const self = this;

            let filtered = self.userImages;
            console.log("User's images:", filtered);

            if (self.terms.length) {
                filtered = filtered.filter(function (image) {
                    const match = (image.keywords || []).some(function (word) {
                        return word.includes(self.terms);
                    });

                    return match;
                });
            }

            return filtered;
        },
        switchNames() {
            return this.switch1 ? "Uploads" : "Images";
        },
    },
    watch: {
        // imageArrayUser(arr) {
        //     this.userImages = arr;
        //     console.log("Image Array User", arr);
        // },
        // terms(newVal) {
        //     console.log("Terms:", newVal);
        // },
    },
};
</script>
<style lang="scss" scoped>
.image-browser {
    display: flex;
    flex-direction: column;
    width: clamp(300px, 80vw, 1200px);
    height: clamp(300px, 80vh, 1200px);

    .image-browser-footer {
        padding: 1em;
        flex: none;
        text-align: center;
        border-top: 1px solid rgba(#000, 0.1);
    }

    .image-browser-header {
        flex: none;
        border-bottom: 1px solid rgba(#000, 0.1);
    }

    .image-browser-nav {
        display: flex;

        a {
            flex: 1;
            display: block;
            padding: 1em;
            text-align: center;
            font-size: 1.2em;
            background: rgba(#000, 0.05);
            border-bottom: 3px solid transparent;
            color: inherit;
            font-weight: bold;

            &.active {
                background: #fff;
                border-bottom-color: var(--primary);
                color: var(--primary);
            }
        }
    }

    .image-browser-body {
        overflow: auto;
        flex: 1;
        background: #fafafa;
    }
}

.empty {
    padding: 3em;
    text-align: center;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

    .cell {
        padding: 1em;
    }
}

.drop-zone {
    margin: 5px;
    padding: 5px;
    width: 150px;
    height: 150px;
    border-radius: 50% 50% 50% 50%;
    border: black dashed 2px;
    transition: box-shadow 0.3s;
    float: left;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
}

.dialog-image {
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: rgba(#000, 0.1);
    border: 2px solid rgba(#000, 0.1);

    &:hover {
        border-color: var(--primary);
    }
}

.search-padder {
    padding: 1em;
}

.search {
    display: block;
    position: relative;
    font-size: 1.4em;

    a {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 100%;
        background: rgba(#000, 0.1);
        color: var(--primary);
    }

    input {
        height: 50px;
        appearance: none;
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 0.5em 1em;
        border: 1px solid rgba(#000, 0.1);
        border-radius: 4em;
        font-size: inherit;

        &:focus {
            outline: 1px solid var(--primary);
            border-color: var(--primary);
        }
    }
}

.dropzone {
    border: 2px dashed rgba(#000, 0.1);
    margin: 1em;
    padding: 1em;
    text-align: center;
    border-radius: 1em;
}

.uploading {
    padding: 1em;
    text-align: center;
}

.image-dialog-gallery {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    column-gap: 10px;
    row-gap: 3px;
    padding: 3px;
    width: 100%;
    height: 250px;
    flex-wrap: wrap;
    padding: 10px;
    border: black solid 2px;
    border-radius: 5px;
    justify-content: center;
}

.premium-mask {
    position: absolute;
    top: 29%;
    left: 30%;
    color: blue;
    opacity: 1;
}

.image-mask {
    filter: grayscale(50%);
    opacity: 0.5;
}

.toggleSwitch {
}
</style>
