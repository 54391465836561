<template>
   <v-row>
      <!-- <v-btn @click="checkingOnisDisabled">hey</v-btn> -->
      <v-col
         class="schedule-container"
         cols="12"
         sm="6"
         md="3"
         v-for="step in steps"
         :key="step.id"
         @drop="$emit('onDrop', $event, step.id)"
         @dragover.prevent
         @dragenter.prevent
         @dragstart="$emit('startDrag', $event, step)"
      >
         <v-card elevation="5" class="schedule-card border" :style="{ maxWidth: screenWidth }">
            <div v-if="step.imgId === 0">
               <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                     <div v-bind="attrs" v-on="on">
                        <AddScheduleImageDialog
                           :stepId="step.id"
                           :imageArray="imageArray"
                           :imageArrayUser="imageArrayUser"
                           @addImageToFrame="addImageToFrame"
                        />
                     </div>
                  </template>
                  <span >Click an Image</span>
                  <span v-else>Drag an Image or Click</span>
               </v-tooltip> -->
               <AddScheduleImageDialog
                           :stepId="step.id"
                           :imageArray="imageArray"
                           :imageArrayUser="imageArrayUser"
                           @addImageToFrame="addImageToFrame"
                        />
            </div>

            <div v-else :draggable="step.imgSrc !== ''">
               <img class="schedule-image" :src="step.imgSrc" alt="" />
            </div>

            <!-- :rules="[() => false || 'required']" -->
            <v-textarea
               class="black--text input-text px-0 pt-1 pt-sm-1 mt-4"
               v-model="step.description"
               name="input-1-1"
               row-height="3"
               label="Add some text"
               auto-grow
               value=""
            ></v-textarea>
            <div class="remove-button-container">
               <v-btn v-if="step.imgSrc" text x-large @click="$emit('removeFrame', $event, step.id)">
                  Remove
               </v-btn>
            </div>
         </v-card>
      </v-col>
   </v-row>
</template>

<script>
import AddScheduleImageDialog from "./add-schedule-image-dialog.vue";
export default {
   name: "ScheduleCard",
   props: ["steps", "imageArray", "yourSchedule", "scheduleId", "imageArrayUser"],
   components: { AddScheduleImageDialog },

   data() {
      return {
         screenWidth: "80%",
      };
   },
   created() {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
   },
   destroyed() {
      window.removeEventListener("resize", this.handleResize);
   },

   mounted() {},

   methods: {
      addImageToFrame($event, stepId, imageId) {
         this.$emit("addImageToFrame", $event, stepId, imageId);
      },
      handleResize() {
         if (window.innerWidth >= 960 && window.innerWidth < 1320) {
            this.screenWidth = "13em";
         } else if (window.innerWidth < 600 && window.innerWidth >= 385) {
            this.screenWidth = "66%";
         } else this.screenWidth = "80%";
      },
   },
};
</script>

<style lang="scss">
.schedule-container {
   display: flex;
   flex-wrap: wrap;
   column-gap: 10px;
   row-gap: 10px;
   justify-content: center;

   .theme--light.v-input input,
   .theme--light.v-input textarea {
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
   }

   .v-text-field .v-label {
      // transform: translateX(30%);
   }
   .v-text-field .v-label::placeholder {
      text-align: center;
   }

   .v-text-field .v-label--active {
      // max-width: 133%;
      // transform: translateY(-18px) translateX(40%) scale(0.75);
   }

   .border {
      border-radius: 15px;
      border-color: black;
      border-width: 2px;
      border-style: solid;
   }

   .schedule-card {
      display: flex;
      flex-direction: column;
      padding-top: 4px;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      .remove-button-container {
         width: auto;
         display: flex;
         justify-content: center;
      }
   }

   .schedule-image {
      padding: 5px;
      margin: 5px;
      // margin: 2rem;
      width: 150px;
      height: 150px;
      border-radius: 50% 50% 50% 50%;
      border: black dashed 2px;
      transition: box-shadow 0.3s;
      float: left;
      cursor: pointer;
      object-fit: cover;
      .remove-button-container {
         width: auto;
         display: flex;
         justify-content: center;
      }

      .v-text-field .v-label {
         transform: translateX(40%);
      }

      .v-text-field .v-label--active {
         max-width: 133%;
         transform: translateY(-18px) translateX(50%) scale(0.75);
      }
   }

   .schedule-image {
      padding: 5px;
      margin: 5px;
      // margin: 2rem;
      width: 150px;
      height: 150px;
      border-radius: 50% 50% 50% 50%;
      border: black dashed 2px;
      transition: box-shadow 0.3s;
      float: left;
      cursor: pointer;

      &:hover {
         box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
      }
   }

   // .drop-zone {
   //   display: flex;
   //   justify-content: center;
   //   align-items: center;
   //   border-radius: 15px;
   //   width: 150px;
   //   height: 150px;
   //   padding: 5px;
   //   cursor: pointer;
   //   white-space: pre;
   //   border: 2px solid white;
   //   margin: 5px;
   // }
}
</style>
