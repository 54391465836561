<template>
   <div content-class="scroll-hide" eager content ref="scheduleDialog" class="displaying dialog-print aspect-ratio-box">
      <v-card class="pa-0" ref="schedule-card" height="90vh">
         <div class="d-flex align-center justify-center">
            <v-row class="d-flex align-center justify-center">
               <v-col
                  cols="3"
                  class="ma-0 d-flex flex-column align-center justify-center"
                  v-for="(card, index) in imageArray"
                  :key="index">
                  <div class="card" ref="cardsss">
                     <v-card class="card-front custom-elevation" rounded>
                        <div class="img-wrapper">
                           <v-img :src="card.imgSrc" class="ma-auto scheduleImg" eager />
                        </div>
                        <v-card-subtitle class="text-image d-flex justify-center align-center flex-column">
                           <div class="image-text">
                              {{ card.stepDescription || card.firstLine }}
                           </div>
                        </v-card-subtitle>
                     </v-card>
                  </div>
               </v-col>
            </v-row>
         </div>
      </v-card>
   </div>
</template>

<script>
import BookDialog from "@/components/library/book-dialog.vue";
import { mapState } from "vuex";

export default {
   data() {
      return {
         dialog: false,
         yourTitle: null,
         images: [],
         chunkSize: 0,
         flipped: false,
         logoImage: null,
         closeBtn: false,
         swipeMessage: false,
      };
   },
   created() {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
   },
   destroyed() {
      window.removeEventListener("resize", this.handleResize);
   },
   components: { BookDialog },

   props: {
      item: Object,
      imageArray: Array,
      schedule: Object,
      showPrintScreen: {
         type: Boolean,
         default: false,
      },
   },
   computed: {
      ...mapState(["assets"]),
      scheduleTitleHeight() {
         return 100;
      },
      showDefaultArrows() {
         return this.$vuetify.breakpoint.lg;
      },
      docDefinition() {
         return {
            images: {
               building: this.assets.logo.logoFull,
            },
         };
      },
      scheduleTitle() {
         return this.schedule?.title;
      },

      scheduleCover() {
         if (!this.schedule?.data?.coverImage) return;
         console.log("get image of image covr", this.schedule?.data);
         return this.getImage(this.schedule?.data?.coverImage);
      },
      chunkArray() {
         let chunked = [];
         for (let i = 0; i < this.imageArray.length; i += this.chunkSize) {
            const chunk = this.imageArray.slice(i, i + this.chunkSize);
            chunked.push(chunk);
         }
         console.log("chunked array:", chunked);
         return chunked;
      },
      allCardsFlipped() {
         return this.flippedCards.length === this.imageArray.length;
      },
   },
   watch: {
      dialog(newVal) {
         console.log("dialog value changed!", newVal);
         if (newVal == false) {
            this.logoImgResolved = false;
            this.resetDialog();
         }
      },
   },

   methods: {
      print() {
         console.log(this.imageArray);
         setTimeout(() => {
            window.print();
         }, 500);
      },
      resetDialog() {
         this.imageArray.map((item) => ({
            ...item,
            flipped: false,
         }));

         console.log("logging imageArray", this.imageArray);
         this.dialog = false;
      },

      handleResize() {
         if (window.innerWidth >= 768) {
            this.chunkSize = 8;
            this.closeBtn = false;
            this.swipeMessage = false;
         } else {
            this.chunkSize = 1;
            this.closeBtn = true;
            this.swipeMessage = true;
         }
         if (window.innerWidth > window.innerHeight && window.innerWidth < 900) {
            this.chunkSize = 1;
            this.swipeMessage = true;
         }
         this.width = window.innerWidth;
      },

      logoImg() {
         this.logoImage = this.assets.logo.logoPng;
      },

      getImage(item) {
         if (item && item._id) if (!item?._id) return;

         console.warn("From printable-schedule!!!");
         return this.$fluro.asset.imageUrl(item._id);
      },
   },
   mounted() {
      this.logoImg();
      this.loading = false;
   },
};
</script>

<style lang="scss">
.dialog-print {
   .v-sheet.v-card {
      box-shadow: none !important;
   }
   .displaying {
      display: block;
      width: 100vw;
      height: 100vh;
      z-index: 999999;
   }

   .aspect-ratio-box {
      position: relative;
      width: 100%;
      padding-bottom: 60%; /* This creates the aspect ratio */
      overflow: hidden;
   }
   .aspect-ratio-box > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
   }

   //    .theme--light.v-card {
   //       background-color: rgb(194, 13, 13);
   //    }

   //    .v-dialog--active {
   //       border-radius: 24px !important;
   //    }

   .no-overflow {
      overflow-y: scroll;
      overflow-x: hidden;
   }
   //    .premium-icon {
   //       position: absolute;
   //       left: 30px;
   //       color: blue;
   //    }
   //    .image-text {
   //       text-align: center;
   //       word-break: keep-all;
   //       height: 3.5em;
   //       font-size: large;
   //       padding: 4px;
   //    }

   .v-card__subtitle,
   .v-card__text,
   .v-card__title {
      padding: 0;
   }

   @media (min-width: 1px) {
      .image-text {
         font-size: 1vw !important;
         height: 0.5vw !important;
         padding: 1vw !important;
      }
      .card {
         position: relative;
         width: 17vw !important;
         height: 20vw !important;
         padding-top: 1vw;
         cursor: pointer;
      }
      .scheduleImg {
         width: 14vw !important;
         height: 14vw !important;
         border: 0.2vw solid rgb(145, 142, 142) !important;
      }

      .theme--dark.v-card {
         border: 0.2vw solid rgb(145, 142, 142) !important;
      }

      //   .title-sizing {
      //      font-size: 3vw !important;
      //   }

      .visual-schedule {
         font-size: 1.5vw;
      }

      .card-front {
         padding-top: 1vw;
      }
   }

   .shadow {
      border-radius: 3px;
      box-shadow: rgba(0, 0, 0, 0.25) 5px 4px 5px 0px;
   }

   .custom-elevation {
      border: 0.2vw solid rgb(145, 142, 142);
   }

   .card-front,
   .card-back {
      position: absolute;
      font-size: 28pt;
      line-height: 100px;
      text-align: center;
      border-radius: 5px;
      width: 18vw;
      height: 25vw;
      //   background: #282a3a;
      transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
      backface-visibility: hidden;
   }

   // dialogbox to be scrollable

   .dialog--fullscreen {
      height: 100%;
      max-height: calc(100vh - 64px);
      overflow-y: auto;
   }

   .v-window__prev,
   .v-window__next {
      top: calc(50% - 10vh);
   }
}
</style>
