<template>
    <v-container fluid>
        <!--Schedule Title-->
        <v-row>
            <input
                v-if="this.scheduleTitle"
                ref="scheduleTitle"
                type="text"
                placeholder="Enter Title Here"
                v-model="edittableTitle"
                class="mx-auto width-auto text-center title-input mb-5"
                maxlength="45"
            />
            <input
                v-else
                ref="scheduleTitle"
                type="text"
                placeholder="Enter Title Here"
                v-model="title"
                class="mx-auto width-auto text-center title-input mb-5"
                maxlength="45"
            />
        </v-row>
        <!--Schedule Card Drop Zones-->
        <v-row class="d-flex align-center justify-center">
            <v-col cols="10" class="d-flex align-center justify-center">
                <ScheduleCard
                    :scheduleId="scheduleId"
                    :steps="steps"
                    :imageArray="imageArray"
                    :yourSchedule="yourSchedule"
                    :imageArrayUser="imageArrayUser"
                    @addImageToFrame="addImageToFrame"
                    @onDrop="onDrop"
                    @startDrag="startDrag"
                    @removeFrame="removeFrame"
                />
            </v-col>
        </v-row>
        <!--Create Button-->
        <v-row no-gutters class="d-flex align-center justify-center my-10">
            <v-col cols="10" class="d-flex align-center justify-center">
                <v-btn
                    v-if="!this.yourSchedule"
                    class="ux-button ma-0"
                    @click.stop.prevent.native="createSchedule"
                    :disabled="isDisabled"
                    x-large
                    >Create</v-btn
                >
                <v-btn v-else class="ux-button" @click.stop.prevent.native="updateSchedule" :disabled="isDisabled" x-large>Update</v-btn>
                <!-- <v-btn
               v-else
               class="justify-center align-center mx-auto"
               style="padding: 34px 80px; border-radius: 5px"
               @click.native="updateSchedule"
               :disabled="isDisabled"
               x-large
               ><span class="font-weight-bold schedules--text text-h5">Update</span></v-btn
            > -->
            </v-col>
        </v-row>
        <template>
            <div class="text-center ma-2">
                <v-snackbar timeout="3000" v-model="IsScheduleMessage">
                    {{ scheduleMessage }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="pink" text v-bind="attrs" @click="IsScheduleMessage = false"> Close </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </template>
        <schedule-dialog ref="scheduleDialogRef"></schedule-dialog>
        <template v-if="false">
            <!--Image Selection-->
            <div style="width: 90vw" class="mx-auto">
                <v-tabs v-model="tab" centered background-color="transparent" class="d-lg-block d-none my-6" color="white" height="5rem">
                    <v-tab :ripple="false">
                        <div class="text-h4 px-10 text-capitalize font-weight-bold">Images</div>
                    </v-tab>
                    <v-tab :ripple="false">
                        <div class="text-h4 px-10 text-capitalize font-weight-bold">Uploads</div>
                    </v-tab>
                </v-tabs>
                <v-tabs-items class="d-lg-block d-none image-selection scroll-hide" v-model="tab">
                    <v-tab-item>
                        <v-row no-gutters class="mx-auto d-lg-flex d-none my-10 align-center justify-center" style="width: 100%">
                            <!-- Load images in and display a lock symbol if they are for premium users -->
                            <template v-if="!loading">
                                <v-col
                                    class="d-lg-flex d-none align-center justify-center py-5"
                                    style="flex: 1 0 18%"
                                    v-for="(image, i) in imageArray"
                                    :key="i"
                                >
                                    <!-- USER AUTH FOR PREMIUM -->
                                    <template v-if="false">
                                        <div class="pa-1 relative">
                                            <v-img
                                                class="rounded-md"
                                                :src="image.imgSrc"
                                                :ref="image._id"
                                                :id="image._id"
                                                @dragstart="startDrag($event, image)"
                                                draggable
                                                eager
                                            />
                                        </div>
                                    </template>
                                    <template v-else-if="image.data.isPremium">
                                        <div class="pa-1 relative image-mask">
                                            <v-img
                                                class="rounded-circle"
                                                :ref="image._id"
                                                :id="image._id"
                                                :src="image.imgSrc"
                                                cover
                                                width="180"
                                                height="180"
                                                eager
                                            >
                                                <v-icon class="premium-mask" size="80">mdi-lock</v-icon>
                                            </v-img>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="pa-1 relative">
                                            <v-img
                                                class="rounded-circle"
                                                :src="image.imgSrc"
                                                width="180"
                                                height="180"
                                                :ref="image._id"
                                                :id="image._id"
                                                @dragstart="startDrag($event, image)"
                                                draggable
                                            />
                                        </div>
                                    </template>
                                </v-col>
                            </template>
                            <template v-else>
                                <v-progress-circular indeterminate />
                            </template>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <div class="upload-images-buttons-container">
                                <!-- <input
                        hidden
                        type="file"
                        name="filefield"
                        class="width-full px-5"
                        accept="image/jpg, image/jpeg, image/png"
                        multiple="multiple"
                        ref="imageUpload"
                        @change="imageSelected"
                     /> -->
                                <v-file-input
                                    v-model="files"
                                    label="Select images to upload"
                                    accept="image/jpg, image/jpeg, image/png"
                                    multiple
                                    ref="imageUpload"
                                    @change="imageSelected"
                                    class="hidden-input"
                                ></v-file-input>
                                <v-btn class="upload-button" @click="uploadImage">
                                    <v-progress-circular
                                        v-if="progress !== 0"
                                        color="secondary"
                                        :value="progress"
                                        stream
                                    ></v-progress-circular>
                                    <v-icon v-else class="filter-tex-color">mdi-upload</v-icon>
                                    <span class="text-body-1 pl-3 filter-tex-color">Upload Photo</span>
                                </v-btn>
                                <div class="d-flex align-center">
                                    <label @click="() => (IsDeleteImageToggle = !IsDeleteImageToggle)" class="mr-2">Show Delete</label>
                                    <v-switch color="red" v-model="IsDeleteImageToggle"></v-switch>
                                </div>
                                <v-btn @click="deleteImages" v-if="IsDeleteImageToggle">
                                    <v-icon class="mr-2">mdi-delete</v-icon>
                                    Delete Images
                                </v-btn>
                            </div>
                        </v-row>
                        <v-row no-gutters class="mx-auto d-lg-flex d-none my-10 align-center justify-center" style="width: 100%">
                            <!-- Load images in and display a lock symbol if they are for premium users -->
                            <template v-if="!loading">
                                <v-col
                                    class="d-lg-flex d-none align-center justify-center py-5"
                                    style="flex: 1 0 18%"
                                    v-for="(image, i) in imageArrayUser"
                                    :key="i"
                                >
                                    <!-- USER AUTH FOR PREMIUM -->
                                    <template v-if="false">
                                        <div class="pa-1">
                                            <v-img
                                                class="rounded-circle"
                                                :src="image.imgSrc"
                                                :ref="image._id"
                                                :id="image._id"
                                                @dragstart="startDrag($event, image)"
                                                draggable
                                                eager
                                            />
                                        </div>
                                    </template>
                                    <template v-else-if="image.data.isPremium">
                                        <div class="pa-1 relative image-mask">
                                            <v-img
                                                class="rounded-circle"
                                                :ref="image._id"
                                                :id="image._id"
                                                :src="image.imgSrc"
                                                cover
                                                width="180"
                                                height="180"
                                                eager
                                            >
                                                <v-icon class="premium-mask" size="80">mdi-lock</v-icon>
                                            </v-img>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="pa-1 relative">
                                            <v-checkbox
                                                color="white"
                                                :class="{ hidden: !IsDeleteImageToggle }"
                                                v-model="image.imgSelected"
                                                :value="image._id"
                                                label=""
                                            ></v-checkbox>
                                            <v-img
                                                class="rounded-circle image-items"
                                                :src="image.imgSrc"
                                                width="180"
                                                height="180"
                                                :ref="image._id"
                                                :id="image._id"
                                                @dragstart="startDrag($event, image)"
                                                draggable
                                                @click="(e) => toggleSelectImage(e, image)"
                                            />
                                        </div>
                                    </template>
                                </v-col>
                            </template>
                            <template v-else>
                                <v-progress-circular indeterminate />
                            </template>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </div>
            <!-- view uploaded images dialog -->
            <!--  <ViewUploadedImagesDialog
         :imageArrayUser="imageArrayUser"
         ref="viewUploadedImagesDialogRef"
         @toggleImageSelection="updateImageArray"
         @pleaseDeleteThis="deleteImages"
         @pleaseUpdateThis="imageSelected"></ViewUploadedImagesDialog> -->
        </template>
    </v-container>
</template>
<script>
import ViewUploadedImagesDialog from "./view-uploaded-images-dialog.vue";
import ScheduleCard from "./schedule-card.vue";
import ScheduleDialog from "@/components/schedule/schedule-dialog.vue";

export default {
    props: ["yourSchedule", "scheduleTitle", "scheduleId"],

    data() {
        return {
            sotredimage: "Leo",
            title: "",
            filterImagesCheckBox: {
                stock: true,
                uploaded: true,
            },
            files: null,
            IsDeleteImageToggle: false,
            cardLimit: 8,
            IsScheduleMessage: false,
            scheduleMessage: "",
            creatingSchedule: false,
            IsShowFilter: false,
            loading: true,
            tab: null,
            progress: 0,
            bytesLoaded: 0,
            bytesTotal: 0,
            imageArray: [],
            //the users personal images
            imageArrayUser: [],
            edittableTitle: "",
            steps: [
                {
                    id: 1,
                    imgId: 0,
                    imgSrc: "",
                    description: "",
                },
                {
                    id: 2,
                    imgId: 0,
                    imgSrc: "",
                    description: "",
                },
                {
                    id: 3,
                    imgId: 0,
                    imgSrc: "",
                    desciption: "",
                },
                {
                    id: 4,
                    imgId: 0,
                    imgSrc: "",
                    description: "",
                },
            ],
        };
    },
    components: { ScheduleCard, ViewUploadedImagesDialog, ScheduleDialog },
    computed: {
        isDisabled() {
            if (this.title?.length > 0 || this.edittableTitle?.length > 0) {
                return false;
            } else {
                return true;
            }
        },

        yourTitle() {
            if (this.scheduleTitle) {
                return this.scheduleTitle;
            } else {
                return this.title;
            }
        },
    },
    methods: {
        updateImageArray(imageUserArray) {
            // console.log("Emitted Image", imageUserArray);
            this.imageArrayUser = imageUserArray;
        },
        handleIsDisabled(isDisabled) {
            // You can access the value of 'isDisabled' here and perform any actions
            // based on its value
            if (isDisabled) {
                console.log("checking on child inout status", isDisabled);
            } else {
                // Do something when 'isDisabled' is false
                console.log("checking on child inout status", isDisabled);
            }
        },

        // modifySteps(imageArray) {
        //    const newSteps = this.steps.map((step, index) => {
        //       const image = imageArray[index];
        //       if (image) {
        //          return {
        //             ...step,
        //             imgId: image.id,
        //             imgSrc: image.imgSrc,
        //             description: image.description,
        //          };
        //       }
        //       return step;
        //    });
        //    this.steps = newSteps;
        //    console.log(this.steps);
        // },

        updateSteps(imageArray) {
            if (imageArray) {
                const emptyStep = {
                    id: imageArray.length + 1,
                    imgId: 0,
                    imgSrc: "",
                    description: "",
                };
                const newSteps = [];
                imageArray.forEach((imgObj, index) => {
                    if (index < 4) {
                        const step = {
                            id: index + 1,
                            imgId: imgObj._id,
                            imgSrc: imgObj.imgSrc,
                            description: imgObj.description,
                        };
                        newSteps.push(step);
                    }
                });
                // newSteps.push(emptyStep);
                if (imageArray.length > 4) {
                    for (let i = 4; i < imageArray.length; i++) {
                        const step = {
                            id: i + 1,
                            imgId: imageArray[i]._id,
                            imgSrc: imageArray[i].imgSrc,
                            description: imageArray[i].description,
                        };
                        newSteps.push(step);
                    }
                }
                this.steps = newSteps;
                if (imageArray.length < 8) {
                    newSteps.push(emptyStep);
                }
                console.log("updated steps", this.steps);
            }
        },

        // click() {
        //    this.updateSteps(this.yourImageArray);
        // },

        openViewImagesDialog() {
            this.$refs.viewUploadedImagesDialogRef.OpenViewImagesDialog();
        },
        deleteImages() {
            //filter images to delete
            let imagesToDelete = this.imageArrayUser.filter((image) => image?.imgSelected && image?.imgSelected !== "");

            //get the image Ids to delete
            let deleteimageIdList = imagesToDelete.map((image) => image?.imgSelected);

            deleteimageIdList.forEach(async (id) => {
                //delete images
                await this.$fluro.api.delete(`/content/scheduleImageUser/${id}`);
            });

            //remove deleted images from imagesUser array
            this.imageArrayUser = this.imageArrayUser.filter((img) => {
                return !deleteimageIdList.includes(img._id);
            });

            //uncheck all the items
            this.imageArrayUser = this.imageArrayUser.map((item) => {
                return {
                    ...item,
                    imgSelected: false,
                };
            });
        },
        toggleSelectImage(e, image) {
            //if image is selected unselect it
            image.imgSelected = image?.imgSelected ? "" : image._id;
        },
        uploadImage() {
            this.tab = 1;
            this.$refs.imageUpload.$el.querySelector("input").click();
        },
        //image processing to upload to fluro
        async imageSelected(fileArrayfromChild) {
            const self = this;
            console.warn("fileArrayFrom Child", fileArrayfromChild);
            // let fileArray;
            // if (fileArrayfromChild) {
            //    fileArray = fileArrayfromChild;
            // } else if (fileArrayfromChild === undefined) {
            //    fileArray = this.$refs.imageUpload.files;
            // }
            let fileArray = self.files;
            console.warn("Oringial file array", fileArray);

            if (fileArrayfromChild && !fileArray) {
                fileArray = fileArrayfromChild;
            }
            console.log("fileArray", fileArray);

            const promises = fileArray.map(function (file) {
                return new Promise(function (resolve, reject) {
                    var formData = new FormData();
                    formData.append(
                        "json",
                        JSON.stringify({
                            _type: "image",
                            realms: ["59028fc41745ab6c2fefeb02"],
                            definition: "scheduleImageUser",
                        })
                    );
                    formData.append("file", file, file.name);
                    ///////////////////////////////////////
                    var config = {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            // ‘Authorization’: ‘Bearer ’ + window.applicationData._application.apikey
                        },
                        onUploadProgress: (progressEvent) => {
                            let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                            // do whatever you like with the percentage complete
                            // maybe dispatch an action that will update a progress bar or something
                            self.progress = percentCompleted;
                            self.bytesLoaded = progressEvent.loaded;
                            self.bytesTotal = progressEvent.total;
                            ///////////////////////////////////////////////////
                            self.$forceUpdate();
                        },
                    };
                    ///////////////////////////////////////
                    self.$fluro.api
                        .post(`/file/upload`, formData, config)
                        .catch(reject)
                        .then(async ({ data }) => {
                            const newImage = {
                                ...data,
                                id: data._id,
                                imgSrc: await self.getImage(data),
                                imgSelected: "",
                            };

                            // self.imageArrayUser.push(newImage);
                            // self.reset();
                            resolve(newImage);
                        });
                });
            });

            Promise.all(promises)
                .catch(function (err) {
                    console.log("Error uploading", err);
                })
                .then(function (images) {
                    console.log("ALL IMAGES UPLOADED", images);
                    self.imageArrayUser = [...self.imageArrayUser, ...images];
                    console.log("Updated image array of user", self.imageArrayUser);
                });
        },
        async createSchedule() {
            const self = this;
            //create IsValid boolean
            //if the schedule has more than one empty discription
            //don't allow submission
            //show error

            //filter empty steps
            let scheduleList = self.steps.filter((step) => step.imgId !== 0);

            if (scheduleList && scheduleList?.length > 0) {
                scheduleList = scheduleList.map((item) => {
                    return {
                        ...item,
                        _id: item.imgId,
                        title: item.description,
                        firstLine: item.description,
                    };
                });

                //Create CoverImage
                let coverImage = {
                    _id: scheduleList[0].imgId,
                    title: self.title,
                    firstLine: self.title,
                };

                self.creatingSchedule = true;

                const data = {
                    title: self.title,
                    definition: "scheduleUser",
                    data: {
                        coverImage: coverImage,
                        scheduleList: scheduleList,
                        testDev: "RedSparrowTest",
                    },
                    realms: ["59028fc41745ab6c2fefeb02"], //may change later
                };

                self.$fluro.api
                    .post(`/content/scheduleUser`, data)
                    .then(({ data }) => {
                        self.scheduleMessage = `${self.title} Schedule added`;
                        self.IsScheduleMessage = true;
                        //open schedule dialogxw
                        // self.$refs.scheduleDialogRef.open(data);
                        // self.reset();
                        // self.creatingSchedule = false;
                        self.$router.push({ name: "schedule" });
                    })
                    .catch((err) => {
                        console.log(err.message);
                        self.scheduleMessage = err.message;
                        self.IsScheduleMessage = true;
                        self.reset();
                        self.creatingSchedule = false;
                    });

                //todo maximum schedule steps
            }
        },

        async updateSchedule() {
            //filter empty steps
            let scheduleList = this.steps.filter((step) => step.imgId !== 0);

            if (scheduleList && scheduleList?.length > 0) {
                scheduleList = scheduleList.map((item) => {
                    return {
                        ...item,
                        _id: item.imgId,
                        title: item.description,
                        firstLine: item.description,
                    };
                });

                //Create CoverImage
                let coverImage = {
                    _id: scheduleList[0].imgId,
                    title: this.yourTitle,
                    firstLine: this.yourTitle,
                };

                this.creatingSchedule = true;

                const data = {
                    title: this.edittableTitle,
                    definition: "scheduleUser",
                    data: {
                        coverImage: coverImage,
                        scheduleList: scheduleList,
                        testDev: "RedSparrowTest",
                    },
                    realms: ["59028fc41745ab6c2fefeb02"], //may change later
                };

                await this.$fluro.api
                    .put(`/content/scheduleUser/${this.scheduleId}`, data)
                    .then(({ data }) => {
                        this.scheduleMessage = `${this.title} Schedule updated`;
                        this.IsScheduleMessage = true;
                        //open schedule dialog
                        // this.$refs.scheduleDialogRef.open(data);
                        // this.reset();

                        this.$router.push({ name: "schedule" });
                    })
                    .catch((err) => {
                        console.log(err.message);
                        this.scheduleMessage = err.message;
                        this.IsScheduleMessage = true;
                        this.reset();
                    });

                this.creatingSchedule = false;

                //todo maximum schedule steps
            }
        },

        async getUserImages() {
            //get userSchedule Image
            this.$fluro.resetCache();
            const dataUser = await this.$fluro.api
                .get("/content/scheduleImageUser", { params: { noCache: true } })
                .then(({ data }) => data)
                .catch((err) => console.log(err));

            let imageArrayUser = await Promise.all(
                dataUser.map(async (image) => {
                    let formattedImage = {
                        ...image,
                        id: image._id,
                        imgSrc: await this.getImage(image),
                    };

                    return formattedImage;
                })
            );

            return imageArrayUser;
        },
        async init() {
            this.dataUser = [];

            const data = await this.$fluro.api
                //get the basic set of images
                .get("/content/scheduleImage")
                .then(({ data }) => data)
                .catch((err) => console.log(err));

            this.imageArray = await Promise.all(
                data.map(async (image) => {
                    let formattedImage = {
                        ...image,
                        id: image._id,
                        imgSrc: await this.getImage(image),
                    };
                    return formattedImage;
                })
            );

            this.imageArrayUser = await this.getUserImages();
            this.loading = false;
        },
        getImage(image) {
            if (!image?._id) return;
            //Reduced Image quality to speed up schedule dialog...
            return `${this.$fluro.asset.imageUrl(image._id, 200, null, { noRedirect: "true", quality: 50 })}`;
        },
        reset() {
            this.progress = 0;
            this.bytesLoaded = 0;
            this.bytesTotal = 0;

            //resets the value of the upload image input
            if (this.$refs.imageUpload?.value) this.$refs.imageUpload.value = "";

            //reset steps form

            this.title = "";

            this.steps = [
                {
                    id: 1,
                    imgId: 0,
                    imgSrc: "",
                    description: "",
                },
                {
                    id: 2,
                    imgId: 0,
                    imgSrc: "",
                    description: "",
                },
                {
                    id: 3,
                    imgId: 0,
                    imgSrc: "",
                    description: "",
                },
                {
                    id: 4,
                    imgId: 0,
                    imgSrc: "",
                    description: "",
                },
            ];
        },
        startDrag(evt, image) {
            evt.dataTransfer.dropEffect = "move";
            evt.dataTransfer.effectAllowed = "move";
            evt.dataTransfer.setData("text/plain", JSON.stringify(image));

            this.$refs.scheduleTitle.scrollIntoView({ behavior: "smooth" });
        },
        onDrop(evt, step) {
            const item = evt.dataTransfer.getData("text/plain");
            const itemObject = JSON.parse(item);

            //If the item is dragged from another schedule card
            if (itemObject && itemObject.imgId) {
                //check if the schedule card limit has been reached
                const itemID = itemObject.imgId;
                const stepObject = this.steps.find((item) => {
                    return item.id === step;
                });

                const imageObject =
                    this.imageArray.find((img) => img.id === itemID) || this.imageArrayUser.find((img) => img.id === itemID);

                stepObject.imgId = itemID;
                stepObject.imgSrc = imageObject.imgSrc;

                let emptyStepsLength = this.steps.filter((imageBox) => imageBox.imgSrc === "").length;

                //check if a new image container needs to be added
                const IsNewAddImageContainerRequired = emptyStepsLength < 1 && this.steps.length < this.cardLimit;

                if (IsNewAddImageContainerRequired) {
                    let newStepId = this.steps.reduce((prev, curr) => {
                        return prev.id > curr.id ? prev : curr;
                    });

                    newStepId = +newStepId.id + 1;
                    this.steps.push({ id: newStepId, imgId: 0, imgSrc: "" });
                }
            } else {
                //check if the schedule card limit has been reached
                this.scheduleCardLimitCheck();

                const itemID = itemObject.id;
                const stepObject = this.steps.find((item) => {
                    return item.id === step;
                });

                const imageObject =
                    this.imageArray.find((img) => img.id === itemID) || this.imageArrayUser.find((img) => img.id === itemID);
                stepObject.imgId = itemID;
                stepObject.imgSrc = imageObject.imgSrc;

                //check if a new image container needs to be added
                const IsNewAddImageContainerRequired =
                    this.steps.filter((imageBox) => imageBox.imgSrc === "").length < 1 && this.steps.length < this.cardLimit;
                if (IsNewAddImageContainerRequired) {
                    let newStepId = this.steps.reduce((prev, curr) => {
                        return prev.id > curr.id ? prev : curr;
                    });
                    newStepId = +newStepId.id + 1;
                    this.steps.push({ id: newStepId, imgId: 0, imgSrc: "" });
                }
            }
        },
        //function to add Image to frame from dialog box
        addImageToFrame($event, stepId, imgId) {
            console.log("Add image to frame?", stepId, imgId);

            this.scheduleCardLimitCheck();
            //get the stepObject
            const stepObject = this.steps.find((step) => step.id === stepId);

            //get the image
            const imageObject = this.imageArray.find((img) => img.id === imgId) || this.imageArrayUser.find((img) => img.id === imgId);

            //assign img source to stepObject
            stepObject.imgId = imgId;
            stepObject.imgSrc = imageObject ? imageObject.imgSrc : this.$fluro.asset.imageUrl(imgId);

            const IsNewAddImageContainerRequired =
                this.steps.filter((imageBox) => imageBox.imgSrc === "").length < 1 && this.steps.length < this.cardLimit;

            if (IsNewAddImageContainerRequired) {
                let newStepId = this.steps.reduce((prev, curr) => {
                    return prev.id > curr.id ? prev : curr;
                });
                newStepId = +newStepId.id + 1;
                this.steps.push({ id: newStepId, imgId: 0, imgSrc: "" });
            }
        },
        //adjust Ids
        removeFrame($event, stepId) {
            //check if a new image container needs to be added
            const emptyFrames = this.steps.filter((imageBox) => imageBox.imgSrc === "");
            if (emptyFrames.length < 1) {
                let newStepId = this.steps.reduce((prev, curr) => {
                    return prev.id > curr.id ? prev : curr;
                });
                newStepId = +newStepId.id + 1;
                this.steps.push({ id: newStepId, imgId: 0, imgSrc: "" });
            }

            const IsEmptyFrame = emptyFrames.some((frame) => frame.id === stepId);
            if (this.steps.length !== 1 && !IsEmptyFrame) {
                //remove frame
                this.steps = this.steps.filter((step) => step.id != stepId);
                //adjust Ids
                this.steps = this.steps.map((step, index) => {
                    return {
                        ...step,
                        id: index + 1,
                    };
                });
            }
        },
        //message when schedule card limit is reached
        scheduleCardLimitCheck() {
            //if schedule limit reached
            if (this.steps.length >= this.cardLimit) {
                this.scheduleMessage = `A schedule has a maximum limit of ${this.cardLimit} cards`;
                this.IsScheduleMessage = true;

                return true;
            }
            return false;
        },
    },
    watch: {
        IsDeleteImageToggle(newVal) {
            if (newVal) {
                this.imageArrayUser = this.imageArrayUser.map((item) => {
                    return {
                        ...item,
                        imgSelected: "",
                    };
                });
            }
        },
    },
    async mounted() {
        await this.init();
        this.updateSteps(this.yourSchedule);
        this.edittableTitle = this.scheduleTitle;
        console.log("editting schedule array", this.scheduleId);
    },
};
</script>
<style lang="scss" scoped>
.hidden-input {
    display: none;
}

.hidden {
    visibility: hidden;
}

.upload-images-buttons-container {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    display: flex;
    column-gap: 25px;
}

.image-selection {
    height: 50vh;
    // overflow: hidden;
    overflow-y: auto;
}

.image-items {
    border-radius: 5%;
}

// .image-selection:hover {
//   overflow-y: auto;
// }
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

html {
    scroll-behavior: smooth;
}

.filter-list {
    position: absolute;
    z-index: 2;
}

.upload-button {
    z-index: 1;
    margin-left: 2.2vw;
    border-radius: 17px !important;

    margin-bottom: 1px;

    .filter-tex-color {
        color: #879be2;
    }
}

.width-full {
    width: 100%;
}

.title-input {
    font-size: 30px;
    font-family: sans-serif;
    color: black;
    opacity: 1;
    background-color: white;
    border-radius: 5px;
}

.title-input::placeholder {
    font-size: 30px;
    font-family: sans-serif;
    color: black;
    opacity: 0.5;
}

.premium-mask {
    position: absolute;
    top: 29%;
    left: 30%;
    color: rgb(198, 198, 198);
    opacity: 1;
}

.image-mask {
    filter: grayscale(50%);
    opacity: 0.5;
}

.schedule-image {
    height: 140px;
    width: 140px;
    border-radius: 15px;
}

.drop-zone {
    border-radius: 15px;
    min-width: 150px;
    min-height: 150px;
    height: auto;
    width: auto;
    padding: 5px;
    cursor: pointer;
    // height: 100%;
    // width: 100%;
    // min-height: 200px;
    // max-height: fit-content;
    // max-width: fit-content;
    white-space: pre;
    // border: 2px dashed black;
    // border: 6px dashed white;
    margin: 5px;
}

.width-full {
    width: 100%;
}

.premium-mask {
    position: absolute;
    top: 30%;
    left: 32%;
}

.image-mask {
    filter: grayscale(50%);
    opacity: 0.5;
}

.test {
    position: absolute;
    background-color: black;
}
</style>
