<template>
   <div class="text-center schedule-dialog">
      <v-dialog v-model="dialog" width="80vw" @input="onDialogClosed">
         <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
                    Click Me
                </v-btn>
            </template> -->

         <v-card class="parentCard">
            <div v-if="$vuetify.breakpoint.mobile" class="closeBtnContainer d-flex justify-space-around">
               <div class="d-flex">
                  <v-btn class="upload-button mt-4" @click="uploadImage">
                     <v-progress-circular
                        v-if="progress !== 0"
                        color="secondary"
                        :value="progress"
                        stream></v-progress-circular>
                     <v-icon v-else class="filter-tex-color">mdi-upload</v-icon>
                     <!-- <span class="text-body-1 pl-3 filter-tex-color">Upload Photo</span> -->
                  </v-btn>
                  <div class="d-flex align-center ml-2">
                     <label @click="() => (IsDeleteImageToggle = !IsDeleteImageToggle)" class="mr-2">
                        <!-- <span>
                              <v-icon>{{ IsDeleteImageToggle ? "mdi-eye-outline" : "mdi-eye-off-outline" }}</v-icon>
                              <span v-if="IsDeleteImageToggle">Hide</span>
                           </span> -->
                     </label>
                     <v-switch color="red" v-model="IsDeleteImageToggle"></v-switch>

                     <v-btn class="ml-3" color="red" @click="deleteImages" v-if="IsDeleteImageToggle">
                        <v-icon>mdi-delete</v-icon>
                     </v-btn>
                     <div class="ml-3 d-flex" v-if="!IsDeleteImageToggle" @click="enableDelete">
                        <v-icon>mdi-delete</v-icon>
                        <div class="questionMark">?</div>
                     </div>
                  </div>
               </div>
               <v-icon v-if="$vuetify.breakpoint.xs" size="35" class="closeBtn" text @click="dialog = false">
                  mdi-close-box-outline
               </v-icon>
            </div>
            <v-row v-else>
               <v-col cols="12" sm="8" class="d-flex align-center justify-center">
                  <v-card-title class="text-h5 grey lighten-2 text-center"> Uploaded Photos </v-card-title>
               </v-col>
               <v-col cols="12" sm="4" class="d-flex align-center justify-center">
                  <div class="d-flex">
                     <v-btn class="upload-button mt-4" @click="uploadImage">
                        <v-progress-circular
                           v-if="progress !== 0"
                           color="secondary"
                           :value="progress"
                           stream></v-progress-circular>
                        <v-icon v-else class="filter-tex-color">mdi-upload</v-icon>
                     </v-btn>
                     <div class="d-flex align-center ml-2">
                        <label @click="() => (IsDeleteImageToggle = !IsDeleteImageToggle)" class="mr-2"> </label>
                        <v-switch color="red" v-model="IsDeleteImageToggle"></v-switch>

                        <v-btn class="ml-3" color="red" @click="deleteImages" v-if="IsDeleteImageToggle">
                           <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <div class="ml-3 d-flex" v-if="!IsDeleteImageToggle" @click="enableDelete">
                           <v-icon>mdi-delete</v-icon>
                           <div class="questionMark">?</div>
                        </div>
                     </div>
                  </div>
               </v-col>
            </v-row>

            <v-card-text>
               <!-- <input
                  hidden
                  type="file"
                  name="filefield"
                  class="width-full px-5"
                  accept="image/jpg, image/jpeg, image/png"
                  multiple="multiple"
                  ref="imageUpload"
                  @change="imageSelected"
               /> -->

               <v-file-input
                  v-model="file"
                  label="Select images to upload"
                  accept="image/jpg, image/jpeg, image/png"
                  multiple
                  ref="imageUpload"
                  @change="imageSelected"
                  class="hidden-input"></v-file-input>

               <v-row :key="cache">
                  <v-col
                     class="d-flex align-center justify-center py-5"
                     style="flex: 1 0 18%"
                     v-for="image in imageArrayUser"
                     :key="image._id">
                     <!-- USER AUTH FOR PREMIUM -->

                     <div class="pa-1 relative">
                        <v-checkbox
                           color="red"
                           :class="{ hidden: !IsDeleteImageToggle }"
                           v-model="image.imgSelected"
                           :value="image._id"
                           label=""
                           ref="checkboxes"></v-checkbox>
                        <v-img
                           class="rounded-circle image-items"
                           :src="image.imgSrc"
                           :ref="image._id"
                           :id="image._id"
                           eager
                           cover
                           width="180"
                           height="180"
                           @click="toggleSelectImage(image)" />
                     </div>

                     <!-- <template v-else-if="image.data.isPremium">
                        <div class="pa-1 relative image-mask">
                           <v-img
                              :ref="image._id"
                              :id="image._id"
                              :src="image.imgSrc"
                              cover
                              width="180"
                              height="180"
                              eager
                           >
                              <v-icon class="premium-mask" size="80">mdi-lock</v-icon>
                           </v-img>
                        </div>
                     </template>
                     <template v-else>
                        <div class="pa-1 relative">
                           <v-checkbox
                              color="white"
                              :class="{ hidden: !IsDeleteImageToggle }"
                              v-model="image.imgSelected"
                              :value="image._id"
                              label=""
                           ></v-checkbox>
                           <v-img
                              class="rounded-md"
                              :src="image.imgSrc"
                              width="180"
                              height="180"
                              :ref="image._id"
                              :id="image._id"
                              @dragstart="startDrag($event, image)"
                              draggable
                              @click="(e) => toggleSelectImage(e, image)"
                           />
                        </div>
                     </template> -->
                  </v-col>
               </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <!-- <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn class="closeBtn" color="primary" text @click="dialog = false">
                  Close
               </v-btn>
            </v-card-actions> -->
         </v-card>
      </v-dialog>
      <!-- <schedule-image></schedule-image> -->
   </div>
</template>

<script>
import scheduleImage from "@/components/schedule/schedule-images.vue";
export default {
   components: { scheduleImage },
   name: "ViewUploadedImagesDialog",
   props: ["imageArrayUser"],

   data() {
      return {
         dialog: false,
         uploadedImageArray: [],
         progress: 0,
         IsDeleteImageToggle: false,
         cache: 0,
         file: null,
      };
   },

   mounted() {},

   computed: {
      deleteImageIcon() {
         return this.IsDeleteImageToggle ? "mdi-delet" : "mdi-delet-circle";
      },
   },

   methods: {
      getImage(image) {
         if (!image?._id) return;
         return `${this.$fluro.asset.imageUrl(image._id)}&noRedirect=true`;
      },
      reset() {
         this.progress = 0;
      },
      OpenViewImagesDialog() {
         this.uploadedImageArray = this.imageArrayUser;
         this.dialog = true;
      },
      enableDelete() {
         this.IsDeleteImageToggle = true;
      },
      deleteImages() {
         //filter images to delete
         let imagesToDelete = this.uploadedImageArray.filter(
            (image) => image?.imgSelected && image?.imgSelected !== ""
         );

         //get the image Ids to delete
         let deleteimageIdList = imagesToDelete.map((image) => image?.imgSelected);

         //  deleteimageIdList.forEach(async (id) => {
         //     //delete images
         //     await this.$fluro.api.delete(`/content/scheduleImageUser/${id}`);
         //  });

         //remove deleted images from uploadedImageArray array
         //  this.uploadedImageArray = this.uploadedImageArray.filter((img) => {
         //     return img.imgSelected === "";
         //  });

         //  //uncheck all the items
         //  this.uploadedImageArray = this.uploadedImageArray.map((item) => {
         //     return {
         //        ...item,
         //        imgSelected: false,
         //     };
         //  });
         this.deleteInParent();
      },

      deleteInParent() {
         this.$emit("pleaseDeleteThis");
      },

      toggleSelectImage(image) {
         this.IsDeleteImageToggle = true;
         image.imgSelected = image?.imgSelected ? "" : image._id;
         this.uploadedImageArray;
         this.uploadedImageArray.map((item) => {
            if (item._id === image._id) {
               return {
                  ...image,
                  imgSelected: !image.imageSelected,
               };
            }
            console.log("returning item", item.imgSelected);
            return item;
         });
         this.updateParentArray(this.uploadedImageArray);
      },
      updateParentArray(array) {
         this.$emit("toggleImageSelection", array);
      },
      uploadImage() {
         this.tab = 1;
         this.$refs.imageUpload.$el.querySelector("input").click();
      },
      onDialogClosed() {
         this.IsDeleteImageToggle = false;
      },

      //get parent to upload image to fluro
      uploadInParent(uploadItem) {
         this.$emit("pleaseUpdateThis", uploadItem);
      },

      //image processing to upload to fluro
      imageSelected() {
         let fileArray = this.file;
         console.log("fileArray", fileArray);

         this.uploadInParent(fileArray);

         //  fileArray.forEach(async (file) => {
         //     ///////////////////////////////////////
         //     var formData = new FormData();
         //     formData.append(
         //        "json",
         //        JSON.stringify({
         //           _type: "image",
         //           realms: ["59028fc41745ab6c2fefeb02"],
         //           definition: "scheduleImageUser",
         //        })
         //     );
         //     formData.append("file", file, file.name);
         //     ///////////////////////////////////////
         //     var config = {
         //        headers: {
         //           "Content-Type": "multipart/form-data",
         //           // ‘Authorization’: ‘Bearer ’ + window.applicationData._application.apikey
         //        },
         //        onUploadProgress: (progressEvent) => {
         //           let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
         //           // do whatever you like with the percentage complete
         //           // maybe dispatch an action that will update a progress bar or something
         //           this.progress = percentCompleted;
         //           this.bytesLoaded = progressEvent.loaded;
         //           this.bytesTotal = progressEvent.total;
         //           ///////////////////////////////////////////////////
         //           this.$forceUpdate();
         //        },
         //     };
         //     ///////////////////////////////////////
         //     let newImage = await this.$fluro.api
         //        .post(`/file/upload`, formData, config)
         //        .catch((err) => {
         //           console.log(err);
         //        })
         //        .then(async ({ data }) => {
         //           console.warn("After image upload", data);

         //           const newImage = {
         //              ...data,
         //              id: data._id,
         //              imgSrc: await this.getImage(data),
         //              imgSelected: "",
         //           };

         //           this.uploadedImageArray.push(newImage);
         //           this.reset();
         //        })
         //        .catch((err) => console.log(err));

         //     this.updateParentArray(this.uploadedImageArray);
         //  });
      },
   },
   watch: {
      IsDeleteImageToggle(newVal) {
         if (newVal) {
            this.uploadedImageArray = this.uploadedImageArray.map((item) => {
               return {
                  ...item,
                  imgSelected: "",
               };
            });
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.parentCard {
   position: relative;
}

.closeBtnContainer {
   position: sticky;
   top: 0.8rem;
   left: 2rem;
   z-index: 1;
}

.closeBtn {
   // display: block;
}

.image-items {
   border-radius: 5%;
}
.hidden-input {
   display: none;
}
.hidden {
   visibility: hidden;
}
.questionMark {
   animation: wiggle 2s linear infinite;
   font-size: large;
   color: red;
}
@keyframes wiggle {
   0%,
   7% {
      transform: rotateZ(0);
   }
   15% {
      transform: rotateZ(-15deg);
   }
   20% {
      transform: rotateZ(10deg);
   }
   25% {
      transform: rotateZ(-10deg);
   }
   30% {
      transform: rotateZ(6deg);
   }
   35% {
      transform: rotateZ(-4deg);
   }
   40%,
   100% {
      transform: rotateZ(0);
   }
}
</style>
